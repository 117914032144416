<template>
	<div class="page">
		<div class="w1000">
			<topHeader :isShowBack="false"></topHeader>
			<div class="top-col" v-if="userInfo.level>0 || myBookExample.length>0">
				<span>您已经添加{{userInfo.hasBooksNum}}本词书，还可添加{{(userInfo.booksNum - userInfo.hasBooksNum)}}本。</span>
				<el-button class="level-up" @click="toBuyVip">升级词书库</el-button>
				<el-button class="level-up" @click="handleduihuan">兑换词书</el-button>
			</div>
			<div class="top-col" v-if="userInfo.level==0 && myBookExample.length<=0">
				<span>新用户可免费添加1本词书</span>
				<el-button class="level-up" @click="changeType(1)">去添加</el-button>
			</div>

			<!-- 筛选组 -->
			<div class="filter-list">
				<div class="filter-item" :class="{ 'checked-item': typeChecked === index }" @click="changeType(index)"
					v-for="(item, index) in bookTypes" :key="index">
					<span>{{ item }}</span>
					<div class="line" v-if="typeChecked === index"></div>
				</div>
			</div>

			<!-- 全部词书列的筛选组 -->
			<div class="other-filter" v-if="typeChecked === 1">
				<div class="label">学习阶段</div>
				<div style="display: flex;flex-wrap: wrap;"><el-button class="other-filter-item" v-for="(item, index) in levels" :key="index"
					:class="{'checked-item': levelChecked === index}" @click="changeLevel(index)">{{ item.title }}</el-button></div>
			</div>
			<!-- 全部词书列的筛选组 -->
			<div class="other-filter" v-if="typeChecked === 1">
				<div class="label">版本</div>
				<div style="display: flex;flex-wrap: wrap;">
					<el-button class="other-filter-item" v-for="(item, index) in versions" :key="index"
						:class="{'checked-item': versionChecked === index}"
						@click="changeVersion(index)">{{ item.title }}</el-button>
				</div>
			</div>
			<!-- 我的词书 -->
			<div class="book-list" v-show="typeChecked === 0">
				<!-- 空 -->
				<div class="data_empty_box" style="padding-top: 68px;" v-if="myBookExample.length<=0">
					<div class="icon"><img class="img100" :src="require('@/assets/static/kong.png')"></img></div>
					<div class="text">暂无数据</div>
				</div>
				<!-- 空 -->
				<wordBook class="book-item" v-for="(item, index) in myBookExample" :key="index"
				    :bookdata="item"
					:bookImg="item.booksDTO.img1"
					:bookTitle="item.booksDTO.title"
					:bookDesc="item.booksDTO.description"
					:readNum="item.readNum"
					:wordNum="item.booksDTO.wordNum"
					:typeChecked="typeChecked"
					@handleedit="handleedit"
					>
				</wordBook>
			</div>
			<!-- 全部词书 -->
			<div class="book-list" v-show="typeChecked === 1">
				<!-- 空 -->
				<div class="data_empty_box" style="padding-top: 68px;" v-if="allBookExample.length<=0">
					<div class="icon"><img class="img100" :src="require('@/assets/static/kong.png')"></img></div>
					<div class="text">暂无数据</div>
				</div>
				<!-- 空 -->
				<wordBook class="book-item" v-for="(item, index) in allBookExample" :key="index"
				    :bookdata="item"
				    :bookImg="item.img1"
					:bookTitle="item.title"
					:bookDesc="item.description"
					:wordNum="item.wordNum"
					:typeChecked="typeChecked"
					@handleAdd="confirmAdd"
					>
				</wordBook>
			</div>
			
		</div>
		<!-- 学习弹窗 -->
		<!-- 制定学习计划的弹窗 -->
		<studyPlan v-if="isShowStudyPlan" :isShow="isShowStudyPlan" :bookwordsNum="addBookItem.wordNum" @cancel="isShowStudyPlan = false" @confirm="handleAdd"></studyPlan>
	</div>
</template>

<script>
	import topHeader from "@/components/topHeader/topHeader.vue";
	import wordBook from "@/components/wordBook/wordBook.vue";
	import studyPlan from "@/components/studyPlan/studyPlan.vue";
	import {mapState,mapMutations} from "vuex";
	import {showLoading,hideLoading} from '@/common/loading.js';
	export default {
		components: {
			topHeader,
			wordBook,
			studyPlan
		},
		data() {
			return {
				bookTypes: ["我的词书", "全部词书"],
				typeChecked: 0,
				levels: [],
				levelChecked: 0,
				versions: [],
				versionChecked: 0,
				myBookExample: [],
				chooseBookItem:{},
				allBookExample: [],
				addBookItem: {},
				isShowStudyPlan:false,//学习计划
				selobj:{
					worknum:10,
					workday:11,
				}
			};
		},
		computed: {
			...mapState(["userInfo", "isLogin"]),
		},
		created() {
			//词书
			this.getMyBooks()
			//阶段
			this.getbooksStage()
		},
		methods: {
			...mapMutations(["SET_USERINFO",'SET_Data']),
			//兑换会员
			handleduihuan(){
				var _this = this
				this.$prompt('请输入兑换码', '兑换会员', {
				        confirmButtonText: '确定兑换',
				        cancelButtonText: '取消',
				        inputPlaceholder:"请输入"
				    }).then(({ value }) => {
						if(value){
							var params = {
								code: value,
								userUuid: _this.userInfo.uuid
							}
							_this.$http.post('app/word/booksGoods/code/geUserVipByCode', params).then(function(res) {
								//数据处理
								if (res.code == 200) {
									//刷新用户信息
									_this.refreshUser()
									_this.$alert('兑换成功！', '提示', {
									    confirmButtonText: '确定',
									    callback: action => {}
									});
								} else {
									_this.$confirm(res.message, '提示', {
										confirmButtonText: '确定',
										cancelButtonText: '取消',
										type: 'warning'
									}).then(() => {}).catch(() => {});
								}
							})
						}else{
							_this.$message({
								type: 'error',
								message: '请输入兑换码 '
							});
						}
						
				    }).catch(() => {
				        this.$message({
				            type: 'info',
				            message: '取消输入'
				        });       
				});
			},
			//刷新用户信息
			refreshUser(){
				var _this = this
				var params = {
					id: this.userInfo.id
				}
				this.$http.post('app/hlexam/hlUser/detail', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						_this.SET_Data({ key: 'userInfo', value:res.data})
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//更换词书
			async handleedit(obj){
				var _this = this
				this.chooseBookItem = obj
				//第一本书,回首页并且自动绑定计划
				showLoading()
				let res = await this.saveuserReading({
					bookId:_this.chooseBookItem.bookId,
					perDayNum: _this.chooseBookItem.perDayNum,//	每天学习量
					readDay: _this.chooseBookItem.readDay,//计划完成天数
					userUuid: _this.userInfo.uuid,
				})
				if(res){
					this.$message.success("更换词书成功！")
				}
			},
			// 确认添加
			confirmAdd(obj) {
				var _this = this
				this.addBookItem = obj
				setTimeout(function(){
					_this.isShowStudyPlan = true
				},300)
			},
			// 确认添加
			handleAdd(obj) {
				var _this = this
				this.selobj = obj
				var params = {
					bookId: this.addBookItem.id,
					userUuid: this.userInfo.uuid,
					perDayNum: this.selobj.worknum,//	每天学习量
					readDay: this.selobj.workday,//计划完成天数
				}
				showLoading()
				this.$http.post('app/word/userBooks/addMyBooks', params).then(function(res) {
					hideLoading()
					//数据处理
					if (res.code == 200) {
						_this.$message.success("加入词书成功！")
						_this.isShowStudyPlan = false
						//刷新信息
						var userInfo = _this.userInfo
						userInfo.hasBooksNum +=1
						_this.SET_USERINFO(userInfo)
						//保存
						sessionStorage.setItem('key_state',JSON.stringify(_this.$store.state))
						if(userInfo.hasBooksNum == 1){
							//第一本书,回首页并且自动绑定计划
							_this.saveuserReading({
								bookId:_this.addBookItem.id,
								perDayNum: _this.selobj.worknum,//	每天学习量
								readDay: _this.selobj.workday,//计划完成天数
								userUuid: _this.userInfo.uuid,
							})
						}else{
							// _this.$router.replace("/")
						}
					} else {
						hideLoading()
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
				
			},
			////保存或修改计划
			saveuserReading(userBooksDTO){
				var _this = this
				return new Promise((resolve, reject) => {
					var params = {
						bookId:userBooksDTO.bookId,
						userUuid: _this.userInfo.uuid,
						userBooksDTO:userBooksDTO
					}
					_this.$http.post('app/word/userReading/edit', params).then(function(res) {
						//数据处理
						hideLoading()
						if (res.code == 200) {
							//首页学习计划
							_this.getMyReading()
							resolve(true)
						} else {
							hideLoading()
							reject(false)
							_this.$confirm(res.message, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {}).catch(() => {});
						}
					})
					
				})
			},
			//首页学习计划
			getMyReading(){
				var _this = this
				//需要登录
				var params = {
					userUuid:this.userInfo.uuid
				}
				this.$http.post('app/word/userReading/getMyReading', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						if(res.data){
							//进度
							if(res.data.userBooksDTO.readNum!=0){
								res.data["progresstext"] = Number(((res.data.userBooksDTO.readNum / res.data.userBooksDTO.booksDTO.wordNum)  * 100).toFixed(0));
							}else{
								res.data["progresstext"] = 0
							}
							if(res.data.userBooksDTO.readNum>0){
								res.data.readStatus = 1
							}
							if(res.data.userBooksDTO.readNum>=res.data.userBooksDTO.booksDTO.wordNum){
								res.data.readStatus = 2
							}
							res.data["perDayNum"] = res.data.userBooksDTO.perDayNum
							_this.SET_Data({ key: 'myReadingObj', value:res.data})
						}
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//去购买等级
			toBuyVip() {
				this.$router.push({
					name:"vipPage",
				});
			},
			//词书
			getMyBooks(){
				var _this = this
				var params = {
					userUuid: this.userInfo.uuid
				}
				this.$http.post('app/word/userBooks/getMyBooks', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						var records = res.data
						if(records.length>0){
							_this.addBookItem = records[0]
						}
						records.forEach((item, index) => {
							if(item.readNum>0){
								item.readStatus = 1
							}
							if(item.readNum>=item.booksDTO.wordNum){
								item.readStatus = 2
							}
						});
						_this.myBookExample = records
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//阶段
			getbooksStage(){
				var _this = this
				var params = {
					currentPage: 1,
					pageSize: 88
				}
				this.$http.post('app/word/booksStage/index', params).then(function(res) {
					if (res.code == 200) {
						_this.levels = res.data.records
						//版本
						_this.getbooksVersion() 
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//版本
			getbooksVersion(){
				var _this = this
				var params = {
					currentPage: 1,
					pageSize: 88
				}
				this.$http.post('app/word/booksVersion/index', params).then(function(res) {
					if (res.code == 200) {
						_this.versions = res.data.records
						//获取词书
						_this.getbooksList()
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//全部词书
			getbooksList(){
				var _this = this
				var params = {
					currentPage: 1,
					pageSize: 8888,
					stageId:this.levels[this.levelChecked].id,
					versionId:this.versions[this.versionChecked].id
				}
				showLoading()
				this.$http.post('app/word/books/index', params).then(function(res) {
					hideLoading()
					//数据处理
					if (res.code == 200) {
						var records =  res.data.records
						// records.forEach((item, index) => {
							
						// });
						_this.allBookExample = records
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			// 变更类型
			changeType(index) {
				this.typeChecked = index;
			},
			// 变更级别
			changeLevel(index) {
				this.levelChecked = index;
				//获取词书
				this.getbooksList()
			},
			// 变更版本
			changeVersion(index) {
				this.versionChecked = index;
				//获取词书
				this.getbooksList()
			},
		},
	};
</script>

<style lang="scss" scoped>
	.top-col {
		display: flex;
		align-items: center;
		height: 40px;
		border-radius: 8px;
		background: #e3f0ff;
		padding: 0 12px;
		font-size: 14px;
		margin-top: 16px;

		.level-up {
			height: 26px;
			width: 100px;
			background: #67adff;
			box-shadow: 0px -2px 0px 0px #3e97fe inset;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			transition: all 0.3s;
			margin-left: 12px;

			&:hover {
				background: #61a4f1;
			}
		}
	}

	.other-filter {
		display: flex;
		align-items: center;
		margin-bottom: 24px;
		margin-left: 24px;

		.label {
			width: 98px;
			color: #222222;
			font-weight: bold;
			font-size: 16px;
			flex-shrink: 0;
		}

		.checked-item {
			background: #e3f0ff;
			color: #4098ff;
			font-weight: bold;
		}

		.other-filter-item {
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			height: 30px;
			margin-left: 8px;
			margin-bottom: 8px;
			border: unset;
		}
	}

	.filter-list {
		display: flex;
		flex-wrap: nowrap;
		margin: 32px 0px 24px 24px;

		.checked-item {
			font-weight: bold;
			color: #222222 !important;
		}

		.filter-item {
			padding: 0 4px;
			height: 40px;
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			line-height: 40px;
			font-size: 16px;
			color: #666666;
			position: relative;
			margin-right: 28px;
			cursor: pointer;

			&:hover {
				color: #444;
			}
		}

		.line {
			height: 2px;
			width: 16px;
			position: absolute;
			bottom: 1px;
			left: 50%;
			transform: translateX(-50%);
			border-radius: 2px;
			background: #51d9a5;
		}
	}

	.book-list {
		display: flex;
		flex-wrap: wrap;

		.book-item {
			margin-right: 20px;
			margin-bottom: 20px;

			&:nth-child(4n) {
				margin-right: unset;
			}
		}

		.no-data {
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 24px;
			color: #222222;
			opacity: 0.5;
			width: 100%;
			margin: 12px 0;
		}
	}
</style>