<template>
	<div class="studyplay_box">
		<el-dialog title="学习计划" center width="50%" top="10vh" :visible.sync="dialogForm"
			:close-on-click-modal="false" @close="handlecancel">
			<div class="studyplay_box_son" style="width: 358px;margin: 0 auto;">
				<el-form :model="formobj" :label-width="formLabelWidth">
					<el-form-item label="每日背单词">
						<el-select @change="bindChangeword" v-model="formobj.worknumindex">
							<el-option v-for="(item, index) in wordsNum" :key="index" :label="item.name" :value="item.index" />
						</el-select>
					</el-form-item>
					<el-form-item label="完成天数">
						<el-select @change="bindChangeday" v-model="formobj.workdayindex">
							<el-option v-for="(item, index) in daysNum" :key="index" :label="item.name" :value="item.index" />
						</el-select>
					</el-form-item>
				</el-form>
				<div class="dialog-footer" style="text-align: center;">
					<el-button @click="handlecancel">返 回</el-button>
					<el-button type="primary" @click="handleconfirm">确认添加词本</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		props: {
			// 在哪用了这个组件
			useSpace: {
				type: Number,
				default: 0
			},
			wordNum: {
				type: Number,
				default: 5
			},
			bookwordsNum: {
				type: Number,
				default: 5
			},
			isShow: {
				type: Boolean,
				default: () => {
					return false;
				}
			},
		},
		watch: {
		    wordNum: {
				immediate:true,
		        handler(newVal, objVal) {
					const index = this.wordsNum.findIndex(item => item == newVal);
					if(index>=0){
						this.checkedWordNum = [index]
						this.checkedDayNum = [index]
					}
		        },
		    },
			bookwordsNum: {
				immediate:true,
			    handler(newVal, objVal) {
					const index = this.wordsNum.findIndex(item => item == newVal);
			        this.thebookwordNum = newVal
			    },
			},
			isShow: {
				immediate:true,
			    handler(newVal, objVal) {
					this.dialogForm = newVal
			    },
			}
		},
		data() {
			return {
				formLabelWidth:"128px",
				dialogForm:false,
				checkedWordNum: [0],//下标
				checkedDayNum: [0],//下标
				thebookwordNum:10,
				wordsNum: (() => {
					const arr = []
					let index = 0
					for (let i = 5; i <= 100; i += 5) {
					    arr.push({
							index:index,
							value:i,
							name:i
						});
						index++;
					}
					return arr
				})(),
				daysNum: (() => {
					// console.log(this.bookwordsNum)
					let num = 1
					const arr = []
					let index = 0
					for (let i = 5; i <= 100; i += 5) {
						let daymun = 1
						if(this.bookwordsNum>i){
							daymun = Math.ceil(this.bookwordsNum / i);
						}
					    arr.push({
							index:index,
							value:daymun,
							name:daymun+"天"
						});
						index++;
					}
					return arr
				})(),
				formobj:{
					worknumindex:0,
					workdayindex:0,
				}
			}
		},
		methods: {
			//取消
			handlecancel(){
				const obj = {
					worknum:this.wordsNum[this.checkedWordNum[0]].value,
					workday:this.daysNum[this.checkedDayNum[0]].value,
				}
				this.$emit("cancel",obj)
			},
		    bindChangeword (e) {
		       //console.log(e)
			   this.formobj.worknumindex = e
			   this.formobj.workdayindex = e
			   this.checkedWordNum = [e]
			   this.checkedDayNum = [e]
		    },
			bindChangeday (e) {
			    //console.log(e)
				this.formobj.worknumindex = e
				this.formobj.workdayindex = e
				this.checkedWordNum = [e]
				this.checkedDayNum = [e]
			},
			//确定
			handleconfirm(){
				const obj = {
					worknum:this.wordsNum[this.checkedWordNum[0]].value,
					workday:this.daysNum[this.checkedDayNum[0]].value,
				}
				this.$emit("confirm",obj)
			},
		}
	}
</script>

<style lang="scss" scoped>

	.color4 {
		background: #499DFF;
		border: solid 1px #499DFF;
	}

	.color6 {
		background:#68CDA6;
		border: solid 1px #68CDA6;
	}
</style>