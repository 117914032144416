<template>
	<div class="this-block">
		<img class="book-pic" :src="bookImg" alt=""/>
		<div class="book-title">
			{{ bookTitle }}
		</div>
		<div class="book-desc">
			{{ bookDesc }}
		</div>
		<div class="mid-line"></div>
		<div class="num-block">
			<div class="finish-num" v-if="readNum">已学{{readNum}}</div>
			<div class="whold-num">总词数{{ wordNum }}</div>
		</div>

		<div @click="$emit('handleedit',bookdata)" class="bottom-btn" v-if="typeChecked === 0" :class="readStatus === 1 ? 'btn-class1' : 'btn-class2'">
			{{ readStatus === 1 ? "当前学习" : "更换词书" }}
		</div>
		<div @click="$emit('handleAdd',bookdata)" class="bottom-btn" v-else :class="readStatus === 1 ? 'btn-class3' : 'btn-class2'">
			{{ readStatus === 1 ? "已添加" : "添加词书" }}
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			bookdata: {
				type: Object,
				default: () => {},
			},
			bookImg: {
				type: String,
				default: '',
			},
			bookTitle: {
				type: String,
				default: '',
			},
			bookDesc: {
				type: String,
				default: '',
			},
			readNum: {
				type: Number,
				default: 0, // 我的词书0， 全部词书1
			},
			wordNum: {
				type: Number,
				default: 0, // 我的词书0， 全部词书1
			},
			readStatus: {
				type: Number,
				default: 0, // 学习状态
			},
			typeChecked: {
				type: Number,
				default: 0, // 我的词书0， 全部词书1
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "@/css/main.scss";

	.this-block {
		width: 235px;
		border-radius: 16px;
		background: #ffffff;
		box-shadow: 0px 3px 15.5px 0px #0000001a;
		padding: 16px;
		cursor: default;

		.book-pic {
			width: 203px;
			height: 254px;
			border-radius: 8px;
		}

		.book-title {
			margin-top: 16px;
			font-weight: bold;
			color: #222;
			font-size: 16px;
			line-height: 21px;
			width: 100%;
			@include text-line(1);
		}

		.book-desc {
			@include text-line(2);
			color: #666666;
			font-size: 14px;
			margin-top: 8px;
			height: 42px;
		}

		.mid-line {
			width: 100%;
			height: 1px;
			margin: 12px 0;
			background: #e5e5e5;
		}

		.num-block {
			display: flex;
			font-size: 14px;
			color: #999999;

			.whold-num {
				margin-left: auto;
			}
		}

		.bottom-btn {
			width: 204px;
			height: 36px;
			padding: 9px 0px 9px 0px;
			border-radius: 16px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			margin-top: 12px;
		}

		.btn-class1 {
			color: #fff;
			box-shadow: 0px -2px 0px 0px #13bc7c inset;
			background: #51d9a5;
		}

		.btn-class2 {
			color: #fff;
			background: #67adff;
			box-shadow: 0px -2px 0px 0px #3e97fe inset;
			cursor: pointer;

			&:hover {
				background: #62a8f8;
			}
		}

		.btn-class3 {
			color: #666666;
			border: 1px solid #e5e5e5;
		}
	}
</style>